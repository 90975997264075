.passport_search_div {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  min-width: 350px;
  display: flex;
  align-items: center;
  padding: 9px 9px 5px 9px;
}
.passport_search_div input {
  border: none;
  outline: none;
  width: 100%;
  text-transform: uppercase;
}
.passport_search_div input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #acacac;
  text-transform: capitalize;
}
