.track_bar_main_div {
}
@media (max-width: 767px) {
  .track_bar_main_div {
    transform: rotateZ(90deg);
    margin: 18rem 0 0 0;
    height: 0px;
    min-width: 500px;
  }
  .trackDataItem {
    transform: rotateZ(-90deg);
  }
  .current_icon {
    transform: rotateZ(-90deg);
  }
}
