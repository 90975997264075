@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.flex_row {
  display: flex;
}
.flex_col {
  display: flex;
  flex-direction: column;
}
.align_center {
  display: flex;
  justify-content: center;
}
.align_center_col {
  display: flex;
  align-items: center;
}
.justify_between {
  justify-content: space-between;
}
.text_center {
  text-align: center;
}
.checkbox_style {
  margin-left: -20px;
}
.paddingGlobal {
  padding: 50px 30px 50px 30px;
}

/* -----------------------------------------------
            max-width 1300 min-width 1024 media query start
-------------------------------------------------*/
@media (max-width: 1300px) and (min-width: 1024px) {
  .paddingGlobal {
    padding: 50px;
  }
}
/* -----------------------------------------------
            max-width 1300 min-width 1024 media query end
-------------------------------------------------*/
/* -----------------------------------------------
            max-width 1023 min-width 568 media query start
-------------------------------------------------*/
@media (max-width: 1023px) and (min-width: 568px) {
  .paddingGlobal {
    padding: 50px 30px 50px 30px;
  }
}
/* -----------------------------------------------
            max-width 1023 min-width 568 media query end
-------------------------------------------------*/
/* -----------------------------------------------
            max-width 567 min-width 320 media query start
-------------------------------------------------*/
@media (max-width: 567px) {
  .paddingGlobal {
    padding: 50px 15px 50px 15px;
  }
}
/* -----------------------------------------------
            max-width 567 min-width 320 media query end
-------------------------------------------------*/

/* -----------------------------------------------
             max-width 320 media query start
-------------------------------------------------*/
@media (max-width: 320px) {
  .paddingGlobal {
    padding: 30px 10px 50px 10px;
  }
}
/* -----------------------------------------------
             max-width 320 media query end
-------------------------------------------------*/
