.visa_invoice {
  padding: 30px 30px;
  font-family: var(--font_nunito);
}
.visa_invoice_second {
  background: #f5faff;
  border-radius: 10px;
  padding: 30px;
  margin-top: 20px;
}
.visa_invoice_details_div {
  display: flex;
  flex-wrap: wrap;
}
.visa_invoice_input_div {
  font-family: var(--font_nunito);
  margin-right: 40px;
  margin-top: 30px;
  width: 280px;
}
.visa_invoice_input_div h5 {
  font-weight: 400;
  font-size: 12px;
}
.visa_invoice_input_inner {
  display: flex;
  align-items: center;
  background: #fdfdfd;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  font-size: 14px;
  padding: 5px 8px;
  margin-top: 5px;
}

.visa_invoice_input_inner input {
  flex: 1;
  outline: none;
  border: none;
  background-color: transparent;
  padding: 5px;
  text-transform: uppercase;
}
.visa_invoice_comments {
  background: #f9f6df;
  border: 1px dashed #bababa;
  border-radius: 15px;
  padding: 15px;
  margin-top: 20px;
  min-height: 80px;
}
.visa_invoice_comments textarea {
  width: 100%;
  height: 80px;
  flex: 1;
  outline: none;
  border: none;
  background-color: transparent;
  padding: 5px;
}
