.sidebar_heading_padding {
  padding: 5px 25px;
}
.logo_sidebar {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.sidebar_routing_section {
  margin-top: 10px;
}
.administration_style {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}
.sidebar_heading_component {
  font-weight: 700;
  font-size: 14px;
  border-radius: 8px;
  padding: 8px 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.sidebar_heading_component h4 {
  margin-left: 12px;
}
.sidebar_sub_heading_component {
  margin-left: 30px;
  padding: 5px 20px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
.sidebar_sub_heading_component,
.sidebar_sub_heading_component h4 {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
@media (max-width: 850px) {
  .hamburger_small_screen {
    position: absolute;
    right: 0px;
  }
}
