@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap");

@font-face {
  font-family: "Gotham";
  src: url(./assets/fonts/Gotham/GothamMedium.ttf) format("truetype");
}
@font-face {
  font-family: "Gotham-light";
  src: url(./assets/fonts/Gotham/GothamBook.ttf) format("truetype");
}
/* @font-face {
  font-family: "Nunito";
  src: url(./assets/fonts/Nunito/Nunito-Regular.ttf) format("truetype"),
    url(./assets/fonts/Nunito/Nunito-Bold.ttf) format("truetype"),
    url(./assets/fonts/Nunito/Nunito-ExtraBold.ttf) format("truetype"),
    url(./assets/fonts/Nunito/Nunito-SemiBold.ttf) format("truetype"),
    url(./assets/fonts/Nunito/Nunito-Light.ttf) format("truetype");
} */

:root {
  --primary: #528dc8;
  --secondary: #75aa52;
  --secondaryDark: #59a85a;
  --tertiary: #838382;
  --font_gotham: "Gotham";
  --font_gotham_thin: "Gotham-light";
  --font_nunito_sans: "Nunito Sans", sans-serif;
  --font_nunito: "Nunito", sans-serif;
  --main_label_size: 14px;
  --sub_label_size: 12px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-block-start: 0em;
  margin-block-end: 0em;
}
a {
  text-decoration: none;
}
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.nunito {
  font-family: var(--font_nunito_sans);
}
.common_heading h2 {
  font-weight: 700;
  font-size: 40px;
}
.common_heading p {
  font-weight: 500;
  font-size: 14px;
}
