.search_div_button {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.search_div_inner {
  margin-left: 15px;
  margin-right: 15px;
}
.singleLineInput {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-right: 100px;
  margin-top: 20px;
}
.first_label {
  margin-right: 20px;
  color: rgb(0, 0, 0);
  white-space: normal;
}
.add_delivery_form {
  padding: 30px 20px;
  background-color: #f9f6df;
  border-radius: 10px;
}

.add_delivery_form h3 {
  color: #856e42;
}
.delivery_address_section {
  margin-right: 40px;
  margin-top: 30px;
  width: 550px;
}

@media (max-width: 500px) {
  .delivery_address_section {
    width: 280px;
  }
}
