.visa_invoice_review_main_div {
  font-family: var(--font_nunito);
}
.visa_invoice_review_second {
  margin-top: 50px;
  justify-content: space-between;
}
.details_item {
  display: flex;
  width: 100%;
  border-top: 1px solid #a3a3a3;
  padding: 7px 0px 7px 10px;
}
.details_div_heading {
  font-size: 20px;
  font-weight: 600;
}
.details_main_div {
  width: 300px;
  /* border: 1px solid gray; */
  margin-top: 8px;
  border-left: 1px solid #a3a3a3;
  border-right: 1px solid #a3a3a3;
  border-bottom: 1px solid #a3a3a3;
  padding-right: 10px;
  font-size: var(--main_label_size);
}
.details_item_label {
  flex: 10%;
  font-weight: 600;
}
.details_item_value {
  flex: 50%;
  font-weight: 400;
  color: #a3a3a3;
}
.visa_invoice_review_table {
  margin-top: 20px;
}
.visa_invoice_review_third {
  margin-top: 36px;
  justify-content: space-between;
}
.passport_info_table {
  margin-top: 10px;
}
.visa_invoice_comment {
  border-radius: 3px;
  border: 1px dashed #bababa;
  outline: none;
  padding: 20px 14px;
  background-color: #f9f6df;
  width: 500px;
}
.visa_invoice_comment::placeholder {
  color: #856e42;
  font-size: var(--main_label_size);
  font-weight: 600;
}
.visa_review_total {
  background-color: #fff;
  color: #000;
  padding: 8px 15px;
  max-width: 320px;
  min-width: 280px;
  font-size: var(--main_label_size);
  font-weight: 700;
  border: 1px solid #000;
  margin-top: 10px;
}
.visa_payment_summary_div_main h4 {
  font-size: 20px;
  font-weight: 800;
}
/* -----------------------------------------------
            max-width 1023 min-width 568 media query start
-------------------------------------------------*/
@media (max-width: 1300px) and (min-width: 1024px) {
  .visa_invoice_comment {
    width: 300px;
  }
  .visa_review_total {
    padding: 8px 15px;
    max-width: 320px;
    min-width: 200px;
  }
}
/* -----------------------------------------------
            max-width 1023 min-width 568 media query end
-------------------------------------------------*/
/* -----------------------------------------------
            max-width 1023 min-width 568 media query start
-------------------------------------------------*/
@media (max-width: 1023px) {
  .visa_invoice_comment {
    width: 380px;
  }
  .visa_review_total {
    max-width: 320px;
    min-width: 220px;
  }
}
/* -----------------------------------------------
            max-width 1023 min-width 568 media query end
-------------------------------------------------*/
/* -----------------------------------------------
            max-width 1023 min-width 568 media query start
-------------------------------------------------*/
@media (max-width: 767px) {
  .visa_invoice_review_third,
  .visa_invoice_review_second {
    flex-direction: column;
  }
  .visa_review_second_inner:nth-child(2) {
    margin-top: 30px;
  }

  .visa_payment_summary_div_main {
    margin-top: 30px;
  }
}
/* -----------------------------------------------
            max-width 1023 min-width 568 media query end
-------------------------------------------------*/
/* -----------------------------------------------
            max-width 567 min-width 320 media query start
-------------------------------------------------*/
@media (max-width: 567px) {
  .visa_invoice_comment {
    width: 250px;
  }
  .visa_review_total {
    padding: 8px 15px;
    max-width: 320px;
    min-width: 250px;
  }
}
/* -----------------------------------------------
            max-width 567 min-width 320 media query end
-------------------------------------------------*/

/* -----------------------------------------------
             max-width 320 media query start
-------------------------------------------------*/
@media (max-width: 320px) {
  .visa_payment_summary_div_main {
    margin-top: 30px;
  }
  .details_main_div {
    width: 250px;
  }
}
/* -----------------------------------------------
             max-width 320 media query end
-------------------------------------------------*/
