.total_cost_div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
.select_date_range_div {
  display: flex;
  align-items: center;
}
.rdrDefinedRangesWrapper,
.rdrDateDisplayWrapper {
  display: none;
}
.date_range_picker_div {
  background-color: white;
  box-shadow: 4px 4px 9px 1px rgba(0, 0, 0, 0.09);
}
.date_range_input_div {
  display: flex;
  min-width: 200px;
  background: #fdfdfd;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  font-size: 18px;
  margin-right: 20px;
  padding: 5px 10px;
}
.date_range_input_div input {
  border: none;
  outline: none;
  flex: 1;
  font-size: 18px;
}
.visa_invoice_section_second {
  display: flex;
}
/* .rdrCalendarWrapper {
  max-width: 300px;
} */
/* .rdrMonthAndYearWrapper,
.rdrMonthsHorizontal {
  max-width: 280px;
} */
@media (max-width: 766px) {
  .rdrMonthAndYearWrapper,
  .rdrMonthsHorizontal {
    max-width: 280px;
  }
}
