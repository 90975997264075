/* Menu Button 6 */
.menu-btn-6 {
  height: 30px;
  width: 30px;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  position: relative;
}
.menu-btn-6 span,
.menu-btn-6 span::before,
.menu-btn-6 span::after {
  background: #528dc8;
  content: "";
  position: absolute;
  width: 30px;
  height: 3px;
  margin-top: 13px;
  border-radius: 1.5px;
  -webkit-transition: 0.3s ease-in-out 0.3s;
  -moz-transition: 0.3s ease-in-out 0.3s;
  -o-transition: 0.3s ease-in-out 0.3s;
  transition: 0.3s ease-in-out 0.3s;
}

.menu-btn-6 span::before {
  margin-top: -8px;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.menu-btn-6 span::after {
  margin-top: 8px;

  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.menu-btn-6.active span {
  background: transparent;

  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;

  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.menu-btn-6.active span::before {
  margin-top: 0;

  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);

  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.menu-btn-6.active span::after {
  margin-top: 0;

  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);

  -webkit-transition-delay: 0.6s;
  -moz-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.5s;
}
