.main_layout_left,
.main_layout_right {
  transition: all 0.5s ease-in-out;
  height: 100vh;
  overflow-y: scroll;
  font-family: var(--font_nunito);
}
.main_layout_right {
  margin-left: 10px;
}
.main_layout_left {
  background-color: #fff;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.main_layout_left::-webkit-scrollbar {
  display: none;
}
.sidebar_open {
  transform: translateX(0);
}
.sidebar_close {
  transform: translateX(-100%);
}
@media (max-width: 850px) {
  .main_layout_left {
    position: absolute;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.246);
    z-index: 10000;
  }
}
