.heading_main_div {
  display: flex;
  justify-content: space-between;
}
.heading_div {
  display: flex;
  align-items: center;
}
.main_print_div {
  padding: 60px 50px;
  font-family: var(--font_nunito);
}
.logo_div {
  margin-right: 15px;
  margin-top: 5px;
}
.heading {
  font-size: 25px;
  font-weight: 700;
}
.invoice_heading {
  font-weight: 600;
  font-size: 25px;
  margin-top: 20px;
}
.bill_company_name {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
}
.address {
  margin-top: 15px;
  max-width: 200px;
  font-weight: 400;
  font-size: 16px;
}
.terms {
  margin-top: 10px;
  color: gray;
  font-weight: 400;
  margin-right: 50px;
}
.terms ul {
  margin-top: 5px;
  margin-left: -15px;
  list-style-type: number;
  font-size: 12px;
}
