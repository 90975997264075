.login_main {
  min-height: 100vh;
}
.login_left {
  flex: 50%;
  background-image: url("../../../assets/images/singapore-river.jpg");
}
.login_right {
  flex: 50%;
  display: flex;
  align-items: center;
}
.login_header_div {
  margin-top: 105px;
}
.login_header {
  font-family: var(--font_gotham);
  color: var(--primary);
  font-size: 40px;
  font-weight: 900;
}
.login_tag_line {
  font-size: 15px;
  font-family: var(--font_gotham_thin);
  font-style: normal;
  color: #000000;
  letter-spacing: 00.36rem;
  margin-left: 9px;
  font-weight: 400;
  margin-top: 20px;
}

.login_form_main_div {
  font-family: var(--font_nunito_sans);
  width: 400px;
  margin-top: 60px;
}
.login_form_main_div h3 {
  font-weight: 700;
  font-size: 25px;
  color: #525252;
}

.login_input_main_div h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #828282;
}

.login_input_main_div {
  margin-top: 20px;
}
.login_input_main_div div {
  margin-top: 4px;
  padding: 12px 10px;
  border: 1px solid #528dc8;
  border-radius: 5px;
  position: relative;
}
.password_div div {
  position: absolute;
  border: none;
  top: 0;
  right: 5px;
}
.login_input_main_div div input {
  border: none;
  outline: none;
  width: 100%;
}
.login_input_main_div div:focus {
  outline: 1px solid #528dc8;
}
.login_input_main_div div input:focus {
  outline: none;
}
.login_input_main_div div input::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: #7d7d7d;
}
.remember_me_div {
  font-weight: 400;
  font-size: 12px;
  color: #a1a1a1;
  margin-bottom: 32px;
}

@media (max-width: 1023px) {
  .login_left {
    display: none;
  }
}
/* -----------------------------------------------
            max-width 567 min-width 320 media query start
-------------------------------------------------*/
@media (max-width: 567px) {
  .login_form_main_div {
    width: 300px;
  }
  .login_header {
    font-size: 2.2rem;
  }
  .login_tag_line {
    font-size: 12px;
    letter-spacing: 00.35rem;
    margin-left: 5px;
  }
  .login_form_main_div h3 {
    font-size: 22px;
  }
  .login_input_main_div div input {
    padding: 0px 6px;
  }
  .remember_me_div {
    margin-bottom: 20px;
  }
  .login_input_main_div div {
    padding-right: 15px;
  }
}
/* -----------------------------------------------
            max-width 567 min-width 320 media query end
-------------------------------------------------*/

/* -----------------------------------------------
             max-width 320 media query start
-------------------------------------------------*/
@media (max-width: 320px) {
  .login_form_main_div {
    width: 267px;
  }
  .login_header {
    font-size: 2rem;
  }
  .login_tag_line {
    font-size: 12px;
    letter-spacing: 00.28rem;
    margin-left: 4px;
  }
  .login_form_main_div h3 {
    font-size: 22px;
  }
  .remember_me_div {
    margin-bottom: 20px;
  }
  .login_input_main_div div {
    padding-right: 15px;
  }
}
/* -----------------------------------------------
             max-width 320 media query end
-------------------------------------------------*/
