.main_header_style {
  display: flex;
  align-items: center;
  padding: 10px;
}
.welcome_name {
  margin-left: 20px;
  display: flex;
  align-items: baseline;
  font-weight: 700;
  font-size: 14px;
  text-transform: capitalize;
}
.welcome_name span {
  color: var(--primary);
}

@media (max-width: 766px) {
  .welcome_name {
    font-size: 16px;
  }
}
