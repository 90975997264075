.status_track_main {
  font-family: var(--font_nunito);
  max-width: 1980px;
  margin: 0 auto;
}
.status_track_first {
  font-family: var(--font_nunito);
  display: flex;
  min-height: 450px;
  justify-content: flex-end;
  background-image: url("../../../assets/images/EnjoyTheWorld.png");
  background-position: left bottom;
  background-size: 55%, contain;
  background-repeat: no-repeat;
  background-color: #f9fcff;
  border-radius: 10px;
  padding: 50px 50px 50px 50px;
}

.status_track_inner_main {
  min-height: 400px;
}
.status_track_inner_main h2 {
  font-weight: 800;
  font-size: 40px;
  color: #000000;
}
.status_track_inner_main h2,
.track_tag_line {
  padding-left: 20px;
}
.track_tag_line {
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}
.status_track_inner_main h2 span {
  color: #528dc8;
}
.status_track_inner {
  box-shadow: 0px 4px 15px 7px #f3f3f3;
  border-radius: 21px;
  padding: 57px 40px;
  margin-top: 25px;
}
.track_by_div,
.track_by_div div {
  display: flex;
  align-items: center;
}
.track_by_div p {
  font-weight: 800;
  margin-right: 20px;
}
.track_by_div p,
.track_by_div div p {
  font-size: 16px;
  color: #000000;
}
.track_by_div div p {
  font-weight: 600;
  margin-right: 0px;
}
.track_by_div div:nth-child(3) {
  margin-left: 30px;
}
.track_enter_details_main {
  margin-top: 20px;
}
.track_enter_details_main h5 {
  font-weight: 700;
  font-size: 20px;
  color: #000000;
}
.track_enter_details_main div {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-x: hidden;
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  padding: 12px 13px;
}
.track_enter_details_main div input {
  border: none;
  outline: none;
  width: 100%;
  background: #f9fafb;
}
.track_enter_details_main div input::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: #acacac;
}
.status_not_find_section {
  margin-top: 25px;
}
.status_not_find_section h4 {
  font-weight: 700;
  font-size: 16px;
}
.status_not_find_section p {
  font-weight: 500;
  font-size: 12px;
  color: rgba(112, 112, 112, 0.7);
}
.status_track_second {
  display: flex;
  justify-content: space-between;
  padding: 10px 90px;
  margin-top: 50px;
}
.status_second_item_text {
  font-weight: 400;
  font-size: 20px;
  color: #b1b1b1;
}
.status_track_third {
  display: flex;
  flex-direction: column;
}
.status_track_third h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 126.69%;
  color: #727272;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 55px;
}
.track_data {
  display: flex;
  justify-content: space-between;
}
.tracking_main_div {
  padding: 0 50px;
}

@media (max-width: 767px) {
  .tracking_main_div {
    padding: 0 10px;
    min-height: 800px;
  }
}
/* -----------------------------------------------
            max-width 567 min-width 320 media query start
-------------------------------------------------*/
@media (max-width: 567px) {
  .status_track_first {
    min-height: 450px;
    justify-content: center;
    background-size: 80%, contain;
    padding: 15px;
  }
  .status_track_second {
    flex-direction: column;
  }
  .status_second_item_text {
    font-size: 16px;
    color: #b1b1b1;
    margin-top: 30px;
  }
  .status_track_third h2 {
    font-size: 30px;
    margin-top: 50px;
    margin-bottom: 55px;
  }
  .status_track_inner_main h2 {
    font-size: 30px;
  }
  .status_track_inner_main h2,
  .track_tag_line {
    padding-left: 0px;
  }
  .track_tag_line {
    font-size: 15px;
  }
  /* track div style */

  .status_track_inner {
    border-radius: 15px;
    padding: 40px 15px;
    background-color: #ffffff;
  }

  .track_by_div p {
    margin-right: 0px;
  }
  .track_by_div p,
  .track_by_div div p {
    font-size: 15px;
  }
  .track_by_div div:nth-child(3) {
    margin-left: 0px;
  }
  .track_enter_details_main {
    margin-top: 20px;
  }
  .track_enter_details_main h5 {
    font-size: 16px;
  }
}
/* -----------------------------------------------
            max-width 567 min-width 320 media query end
-------------------------------------------------*/
