.spinner {
  width: 1.5em;
  height: 1.5em;
  border: 2px solid transparent;
  border-top-color: #e9e0e3;
  border-bottom-color: #e7dfe2;
  border-radius: 50%;
  animation: spin-stretch 2s ease infinite;
}

@keyframes spin-stretch {
  50% {
    transform: rotate(360deg) scale(0.4, 0.33);
    border-width: 8px;
  }
  100% {
    transform: rotate(720deg) scale(1, 1);
    border-width: 3px;
  }
}
