.dashboard_main_div {
  padding: 20px 40px;
}
.dashborad_summary_item {
  width: 18%;
  margin-right: 15px;
  margin-top: 25px;
}
.dashborad_financial_item {
  width: 30%;
  margin-right: 15px;
  margin-top: 20px;
}
.dashboard_chart_section {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  padding: 20px 50px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1), #dce7f2a5);
}
.visa_chart canvas {
  max-width: 450px;
  min-height: 250px;
}
.chart_main_div {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 40px;
}
.chart_main_div h5 {
  font-size: 14px;
}
.visa_chart {
  margin-top: 20px;
}
@media (max-width: 1000px) {
  .dashborad_summary_item,
  .dashborad_financial_item {
    width: 45%;
  }
  .dashboard_chart_section {
    flex-direction: column;
    align-items: center;
  }
  .chart_main_div {
    margin-top: 80px;
  }
}

@media (max-width: 600px) {
  .dashborad_summary_item,
  .dashborad_financial_item {
    width: 80%;
  }
  .visa_chart canvas {
    max-width: 380px;
    min-height: 200px;
  }
}
@media (max-width: 400px) {
  .dashborad_summary_item,
  .dashborad_financial_item {
    width: 80%;
  }
  .visa_chart canvas {
    max-width: 300px;
    min-height: 200px;
  }
}
