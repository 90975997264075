.passport_return_main_div {
  padding: 40px 20px;
  font-family: var(--font_nunito);
}
.passport_return_header,
.passport_return_pagination_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.passport_return_header_buttons {
  display: flex;
}
.passport_heading {
  font-weight: 700;
  font-size: 24px;
  color: var(--primary);
}

.passport_return_header_button_download,
.buttons_right_margin {
  margin-right: 20px;
}
.passport_return_second,
.passport_return_pagination_section {
  margin-top: 40px;
}

.passport_filter_div {
  position: absolute;
  top: 50px;
  z-index: 10;
  background-color: white;
  background: #ffffff;
  border: 2px solid #ebeff2;
  box-shadow: 4px 4px 9px -1px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  padding: 15px 0;
}
.filter_div_common_section {
  padding: 10px 20px;
  border-bottom: 1px solid #ebeff2;
  display: flex;
  align-items: center;
}
.filter_div_common_section_tag {
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  margin-left: 5px;
  white-space: nowrap;
}
.filter_div_visa_applied {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.filter_div_visa_countries {
  padding: 0px 10px 10px 40px;
  max-height: 450px;
  overflow: hidden;
  overflow-y: scroll;
}

/* .pagination_passport {
  margin-right: 80px;
} */
.pagination_select_div {
  border: 1px solid #8d949e;
  border-radius: 4px;
  padding: 7px 15px;
  color: #8d949e;
  display: flex;
  align-items: center;
}
.pagination_select_div select {
  color: #8d949e;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  margin-right: 10px;
}
.passport_return_pagination_section {
  padding: 10px 50px;
}

@media (max-width: 766px) {
  /* .pagination_passport {
    margin-right: 0px;
  } */
  .passport_return_header_button_download,
  .buttons_right_margin {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .passport_return_main_div {
    padding: 40px 10px;
    font-family: var(--font_nunito);
  }
  .passport_return_header,
  .passport_return_pagination_section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .passport_return_header_buttons {
    display: flex;
    flex-direction: column;
  }
  .passport_heading {
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 40px;
  }
  .pagination_passport {
    margin-top: 20px;
  }
}
.comment_in_modal {
  border: 1px solid #8d949e;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  margin-top: 10px;
}

.comment_in_modal textarea {
  border: none;
  outline: none;
  flex: 1;
}
